import React from 'react'
// Redux
import { useSelector } from 'react-redux';
// Grommet
import { Grommet } from 'grommet';
import { Layout } from './default/Layout';

const theme = {
  global: {
    colors: {
      //brand: '#228BE6',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

export function Default(props) {
  // Retrieve values from state with selectors
  const path = useSelector((state) => state.nav.path)

  return (
    <Grommet
      full={true}
      theme={theme}
    >
      <Layout path={path} {...props}></Layout>
    </Grommet>
  )
}
