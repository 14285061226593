import { createSlice } from '@reduxjs/toolkit'

// Determine the current url to set initial state on load/reload
const url = new URL(document.location.href);
const queryString = window.location.search;
const params = new URLSearchParams(queryString);

// Initial redux state
const initialState = {
  host: url.hostname,
  path: url.pathname,
  origin: url.origin,
  params: {
    id: params.get('id')
  }
}

// Slice
export const navSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    navigate: (state, action) => {
      state.path = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const {
  navigate,
} = navSlice.actions

// Export redux selectors
export const selectPath = state => state.nav.path;

/**
 * Thunk middleware to perform navigation
 * @param {*} path path to navigate to
 */
export const navigateToPath = (e, path) => async (dispatch) => {
  // Update URL by adding event into browser history (allows back button)
  window.history.pushState(null, "", path);
  dispatch(navigate(path));
};

// Export reducer
export default navSlice.reducer
