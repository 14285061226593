import React from 'react'
// Redux
import { useSelector } from 'react-redux';
// Layouts
import { Default } from './layouts/default';

export function Router() {
  // Retrieve values from state with selectors
  const path = useSelector((state) => state.nav.path)

  var title = 'Home';

  switch (path) {
    case '/articles':
      title = 'Articles';
      break;
    case '/projects':
      title = 'Projects';
      break;
    default:
      break;
  }

  return (
    <Default title={'Voquis | ' + title}></Default>
  )
}
