// CSS
import 'bootstrap/dist/css/bootstrap.css';
import './css/styles.css';
// images
import earth from './img/earth.png'
import './img/pyrenees.jpg'
// Font awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
// Components
import { Form } from './Form';

export function Layout(props) {
  return (
    <>
        {/* Navigation */}
        <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
            <div class="container">
                <a class="navbar-brand js-scroll-trigger logo" href="#page-top">VOQUIS</a>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#projects">Technologies</a></li>
                        <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#signup">Contact</a></li>
                    </ul>
                </div>
            </div>
        </nav>
        {/* About */}
        <section class="about-section text-center" id="home">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 mx-auto">
                        <h2 class="text-white mb-4">Accelerate your journey to cloud computing</h2>
                        <p class="text-white-50">
                            Voquis is a technology consultancy specialising in software lift-and-shift, application new-build and infrastructure provisioning.
                        </p>
                    </div>
                </div>
                <img class="img-fluid" src={earth} alt="" />
            </div>
        </section>
        {/* Projects */}
        <section class="projects-section bg-light" id="projects">
            <div class="container">
                {/* Project One Row */}
                <div class="row justify-content-center no-gutters mb-5 mb-lg-0">
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Serverless Computing</h4>
                                    <p class="mb-0 text-white-50">Reduce the overhead of managing servers by breaking apart applications into specialised components.</p>
                                    <hr class="d-none d-lg-block mb-0 mr-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Containerisation</h4>
                                    <p class="mb-0 text-white-50">Architect self-contained and portable applications to run in isolated compute environments.</p>
                                    <hr class="d-none d-lg-block mb-0 ml-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project Two Row */}
                <div class="row justify-content-center no-gutters">
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Build &amp; Deploy</h4>
                                    <p class="mb-0 text-white-50">Continuously build, test and deploy software and infrastructure from pipelines.</p>
                                    <hr class="d-none d-lg-block mb-0 ml-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 order-lg-first">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Infrastructure as Code (IaC)</h4>
                                    <p class="mb-0 text-white-50">Manage multi-cloud deployments across environments with self-documenting code.</p>
                                    <hr class="d-none d-lg-block mb-0 mr-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Project Three Row */}
                <div class="row justify-content-center no-gutters mb-5 mb-lg-0">
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-right">
                                    <h4 class="text-white">Data Engineering</h4>
                                    <p class="mb-0 text-white-50">Build data retrieval and storage pipelines, coupled with extract, transform and load (ETL) processing.</p>
                                    <hr class="d-none d-lg-block mb-0 mr-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="bg-black text-center h-100 project">
                            <div class="d-flex h-100">
                                <div class="project-text w-100 my-auto text-center text-lg-left">
                                    <h4 class="text-white">Identity Management</h4>
                                    <p class="mb-0 text-white-50">Control user and application permissions with enterprise directories and single sign-on.</p>
                                    <hr class="d-none d-lg-block mb-0 ml-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        {/* Signup */}
        <section class="signup-section" id="signup">
            <div class="container">
                <div class="row">
                    <div class="col-md-10 col-lg-8 mx-auto">
                        <h2 class="text-white mb-5 text-center">Discuss your project</h2>
                        <Form></Form>
                    </div>
                </div>
            </div>
        </section>

        {/* Contact */}
        <section class="contact-section bg-black">
            <div class="container">
                <div class="social d-flex justify-content-center">
                    <a class="mx-2" href="https://hub.docker.com/r/voquis"><FontAwesomeIcon icon={faDocker} /></a>
                    <a class="mx-2" href="https://github.com/voquis"><FontAwesomeIcon icon={faGithub} /></a>
                    <a class="mx-2" href="https://www.linkedin.com/company/voquis"><FontAwesomeIcon icon={faLinkedin} /></a>
                </div>
            </div>
        </section>

        {/* Footer */}
        <footer class="footer bg-black small text-center text-white-50">
            <div class="container">
                Copyright &copy; { new Date().getFullYear() } Voquis Limited, a Private Limited Company registered in England and Wales.<br />
                Image credits <a href="https://pixabay.com/users/witizia-261998">Witizia</a>, <a href="https://pixabay.com/users/piro4d-2707530">PIRO.</a><br />
                <small>Version: {process.env.REACT_APP_COMMIT || 'local'}</small>
            </div>
        </footer>
    </>
  )
}
