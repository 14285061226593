// Redux toolkit features
import { Router } from "./Router";

function App() {
  return (
    <Router></Router>
  );
}

export default App;
