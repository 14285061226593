// React
import React, { useState } from 'react';
// hCaptcha
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useFormik } from 'formik';
import axios from 'axios'
// App Config
import { API_BASE_URL, HCAPTCHA_SITEKEY } from '../../Config';

export function Form(props) {
  // Create local state
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [submitErrorMessage, setSubmitErrorMessage] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(null);

  // Configure form events
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {

      // Set form state to submitting
      setSubmitting(true);
      // Reset any existing state
      setSubmitError(null);
      setSubmitErrorMessage(null);
      setSubmitSuccess(null);

      // Send data to backend
      axios.post(`${API_BASE_URL}/contact`, values)
      .then(function (response) {
        // Handle successful case
        setSubmitSuccess(true);
      })
      .catch(function (error) {
        // Handle error case
        setSubmitError(true);
        if (error.response) {
          const message = error.response.data.message || '';
          setSubmitErrorMessage(message)
        }
      })
      .then(function () {
        // Remove submitting state on success or failure
        setSubmitting(false);
      });
    }
  });

  // Build alert for success or failure
  let alert;

  if (submitSuccess) {
    alert = <div class="alert alert-success" role="alert">
      Message sent successfully
    </div>;
  }

  if (submitError) {
    alert = <div class="alert alert-danger" role="alert">
      There was an error sending your message, please try again. {submitErrorMessage}
    </div>;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div class="row">
          <div class="col">
              <div class="form-group">
                  <input
                    type="text"
                    class="form-control form-control-lg"
                    name="name"
                    placeholder="Name"
                    required
                    onChange={formik.handleChange}
                  />
              </div>
          </div>
          <div class="col">
              <div class="form-group">
                  <input
                    type="email"
                    class="form-control form-control-lg"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={formik.handleChange}
                  />
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <div class="form-group">
                  <textarea
                    class="form-control form-control-lg"
                    name="message"
                    rows="6"
                    placeholder="Details"
                    required
                    onChange={formik.handleChange}
                  ></textarea>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <div class="form-group">
                <input
                  type="hidden"
                  name="captcha-response"
                  onChange={formik.handleChange}
                />
                <HCaptcha
                  theme="dark"
                  reCaptchaCompat={false}
                  sitekey={HCAPTCHA_SITEKEY}
                  onVerify={(token) => formik.setFieldValue('captcha-response', token)}
                />
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col">
              <div class="form-group">
                  <button type="submit" disabled={submitting ? 'disabled' : ''} class="btn btn-primary">Submit</button>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col">
            { alert }
          </div>
      </div>
    </form>
  )
}
